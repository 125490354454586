import React from 'react'
import moment from "moment"

class Form extends React.Component {
    constructor(props) {
        super(props)
        this.apiURL = process.env.REACT_APP_API_URL
        this.state = props.entity
    }

    updateField(e, key) {
        this.setState({[key]: e.target.value})
    }

    updateBoolean(value, key) {
        this.setState({[key]: value})
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        return (
            <form onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
                <div className="actions clearfix">
                    <div className="buttons clearfix">
                        <button className="grey"
                                onClick={(e) => this.onCancel(e, () => this.props.cancel())}>Cancel
                        </button>
                        <button className="blue">Save</button>
                    </div>
                </div>

                <div className="block">
                    <div className="fields clearfix">
                        {
                            Object.keys(this.props.fields).map((field) => {
                                return (
                                    <div className="fields clearfix">
                                        <h3>{this.props.fields[field].label}</h3>
                                        {
                                            Object.keys(this.props.fields[field].fields).map((element) => {
                                                return (
                                                    <>
                                                        {
                                                            this.props.fields[field].fields[element].type === 'textarea' &&
                                                            <div className="wide-field" key={element}>
                                                                <label>{this.props.fields[field].fields[element].label}</label>
                                                                {/*<input type="textarea"*/}
                                                                {/*       onChange={(e) => this.updateField(e, element)}*/}
                                                                {/*       required={this.props.fields[field].fields[element].required}*/}
                                                                {/*       disabled={this.props.fields[field].fields[element].disabled}*/}
                                                                {/*       value={this.state[element]}/>*/}
                                                                <textarea onChange={(e) => this.updateField(e, element)}
                                                                          required={this.props.fields[field].fields[element].required}
                                                                          disabled={this.props.fields[field].fields[element].disabled}
                                                                          value={this.state[element]}/>
                                                            </div>
                                                        }
                                                        {
                                                            this.props.fields[field].fields[element].type === 'image' &&
                                                            <div className="pictures" key={element}>
                                                                {
                                                                    this.state.pictures !== undefined &&
                                                                    Object.keys(this.state.pictures).map((picture) => {
                                                                        return (
                                                                            <a href={this.apiURL + this.props.imgPath + this.state.pictures[picture].uri}
                                                                               target="_blank">
                                                                                <img
                                                                                    src={this.apiURL + this.props.imgPath + this.state.pictures[picture].uri}
                                                                                    alt='pics'/>
                                                                            </a>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    this.state.images !== undefined &&
                                                                    Object.keys(this.state.images).map((image) => {
                                                                        return (
                                                                            <a href={this.apiURL + this.props.imgPath + this.state.images[image].uri}
                                                                               target="_blank">
                                                                                <img
                                                                                    src={this.apiURL + this.props.imgPath + this.state.images[image].uri}
                                                                                    alt='imgs'/>
                                                                            </a>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            // this.props.fields[field].fields[element].display !== false &&
                                                            this.props.fields[field].fields[element].type !== 'textarea' &&
                                                            this.props.fields[field].fields[element].type !== 'image' &&
                                                            <div className="field" key={element}>
                                                                <label>{this.props.fields[field].fields[element].label}</label>
                                                                {
                                                                    this.props.fields[field].fields[element].type === 'text' &&
                                                                    <input type="text"
                                                                           onChange={(e) => this.updateField(e, element)}
                                                                           required={this.props.fields[field].fields[element].required}
                                                                           disabled={this.props.fields[field].fields[element].disabled}
                                                                           value={this.state[element]}/>
                                                                }
                                                                {
                                                                    this.props.fields[field].fields[element].type === 'password' &&
                                                                    <input type="password"
                                                                           onChange={(e) => this.updateField(e, element)}
                                                                           required={this.props.fields[field].fields[element].required}
                                                                           disabled={this.props.fields[field].fields[element].disabled}
                                                                           value={this.state[element]}/>
                                                                }
                                                                {
                                                                    this.props.fields[field].fields[element].type === 'number' &&
                                                                    <input type="number"
                                                                           onChange={(e) => this.updateField(e, element)}
                                                                           required={this.props.fields[field].fields[element].required}
                                                                           disabled={this.props.fields[field].fields[element].disabled}
                                                                           value={this.state[element]}/>
                                                                }
                                                                {
                                                                    this.props.fields[field].fields[element].type === 'date' &&
                                                                    <input type="date"
                                                                           onChange={(e) => this.updateField(e, element)}
                                                                           required={this.props.fields[field].fields[element].required}
                                                                           disabled={this.props.fields[field].fields[element].disabled}
                                                                           value={moment(this.state[element]).format('YYYY-MM-DD')}/>
                                                                }
                                                                {
                                                                    this.props.fields[field].fields[element].type === 'email' &&
                                                                    <input type="email"
                                                                           onChange={(e) => this.updateField(e, element)}
                                                                           required={this.props.fields[field].fields[element].required}
                                                                           disabled={this.props.fields[field].fields[element].disabled}
                                                                           value={this.state[element]}/>
                                                                }
                                                                {
                                                                    this.props.fields[field].fields[element].type === 'select' &&
                                                                    <select
                                                                        onChange={(e) => this.updateField(e, element)}
                                                                        required={this.props.fields[field].fields[element].required}
                                                                        disabled={this.props.fields[field].fields[element].disabled}
                                                                        value={this.state[element]}>
                                                                        {
                                                                            this.props.fields[field].fields[element].options.map(option =>
                                                                                <option
                                                                                    value={option.key}>{option.label}</option>)
                                                                        }
                                                                    </select>
                                                                }
                                                                {
                                                                    this.props.fields[field].fields[element].type === 'boolean' &&
                                                                    <div className="radio">
                                                                        <input type="radio" name={element}
                                                                               onChange={e => this.updateBoolean(true, element)}
                                                                               checked={this.state[element] === true ? true : false}/>
                                                                        <span>Yes</span>
                                                                        <input type="radio" name={element}
                                                                               onChange={e => this.updateBoolean(false, element)}
                                                                               checked={this.state[element] === false ? true : false}/>
                                                                        <span>No</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {
                    this.props.deleteEntity &&
                    <div className="actions clearfix below-form">
                        <button onClick={() => this.props.deleteEntity()} className="red">Delete</button>
                    </div>
                }
            </form>
        )
    }
}

export default Form