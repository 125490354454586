import React from 'react'
import PreviewImage from '../../layout/previewImage.jsx'
import Dropzone from './dropzone.jsx'

function FileHandler(props) {
    const typeProps = props.type
    const pathProps = props.path
    const indexProps = props.index

    return (
        <>
            {
                props.dropzone === true ?
                    <Dropzone
                        showImage={(acceptedFile, acceptedFileTemp) => props.showImage(acceptedFile, acceptedFileTemp, typeProps, indexProps)}
                        fileType={props.fileType}
                        path={pathProps}
                        label={props.label}
                    />
                    :
                    props.fileType === 'images' ?
                        <PreviewImage
                            preview={props.image}
                            name={props.name}
                            label={props.label}
                            removeImage={() => props.removeImage(typeProps)}
                        />
                        :
                        <div></div>
            }
        </>
    )
}

export default FileHandler