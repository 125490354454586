import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import Header from './header'
import Login from '../account/login'

import PrivateRoute from './privateRoute'

/* Complaints */
import ComplaintList from '../complaints/list.jsx'
import ComplaintView from '../complaints/view.jsx'

/* Reports */
import ReportList from '../reports/list.jsx'
import ReportEdit from '../reports/edit.jsx'

/* Users */
import UserList from '../users/list.jsx'
import UserNew from '../users/new.jsx'
import UserEdit from '../users/edit.jsx'

/* Categories */
import CategoryList from "../categories/list.jsx"
import CategoryNew from '../categories/new.jsx'
import CategoryEdit from '../categories/edit.jsx'

/* Pages */
import PageList from "../pages/list.jsx"
import PageEdit from "../pages/edit.jsx"

/* Brands */
import BrandList from '../brands/list.jsx'
import BrandNew from '../brands/new.jsx'
import BrandEdit from '../brands/edit.jsx'

/* Products */
import ProductList from '../brands/products/list.jsx'
import ProductNew from '../brands/products/new.jsx'
import ProductEdit from '../brands/products/edit.jsx'

function Layout() {
	return (
		<Router>
			<Header />
			<main id="main">
				<ToastContainer position="top-right" />
				<Switch>
					<Route exact path="/login" component={Login} />
					<PrivateRoute exact path="/reports" component={ReportList} />
					<PrivateRoute exact path="/reports/:reportId/edit" component={ReportEdit} />

					<PrivateRoute exact path="/users" component={UserList} />
					<PrivateRoute exact path="/users/new" component={UserNew} />
					<PrivateRoute exact path="/users/:userId/edit" component={UserEdit} />

					<PrivateRoute exact path="/categories" component={CategoryList} />
					<PrivateRoute exact path="/categories/new" component={CategoryNew} />
					<PrivateRoute exact path="/categories/:categoryId/edit" component={CategoryEdit} />

					<PrivateRoute exact path="/complaints" component={ComplaintList} />
					<PrivateRoute exact path="/complaints/:complaintId" component={ComplaintView} />

					<PrivateRoute exact path="/pages" component={PageList} />
					<PrivateRoute exact path="/pages/:pageId/edit" component={PageEdit} />

					<PrivateRoute exact path="/brands" component={BrandList} />
					<PrivateRoute exact path="/brands/new" component={BrandNew} />
					<PrivateRoute exact path="/brands/:brandId/edit" component={BrandEdit} />

					<PrivateRoute exact path="/brands/:brandId/products" component={ProductList} />
					<PrivateRoute exact path="/brands/:brandId/products/new/:section" component={ProductNew} />
					<PrivateRoute exact path="/brands/:brandId/products/:productId/edit/:section" component={ProductEdit} />

					<Route
						exact
						path="/"
						render={() => {
							return (
								<Redirect to="/reports" />
							)
						}}
					/>
				</Switch>
			</main>
		</Router>
	)
}

export default Layout
