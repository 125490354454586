import React from 'react';
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'

class PageList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			filters: {},
			pages: {
				columns: [
					{
						label: 'Pages',
						key: 'pageName'
					},
					{
						label: 'Page ID',
						key: 'pageId'
					},
					{
						content: 'Edit',
						action: 'edit',
						className: 'edit-link',
						onClick: (id, key) => {
							this.props.history.push('/pages/' + id + '/edit')
						}
					}],
				entities: [],
				filters: [],
				actions: [],
				selectedItems: [],
				multiSelect: false,
				// onRowClick: (entity) => {
				// 	this.props.history.push('/pages/' + entity.id + '/edit')
				// }
			}
		}
	}

	componentDidMount() {
		API.getPages(this.state.filters).then(entities => this.setState({
			...this.state,
			loading: false,
			pages: {...this.state.pages, entities: entities}
		}))
	}

	loadEntities() {
		API.getPages(this.state.filters).then(entities => this.setState({
			...this.state,
			loading: false,
			pages: {...this.state.pages, entities: entities}
		}))
	}

	render() {
		if (this.state.loading)
			return <Loader/>
		else
			return <>
				<Head title={'Pages'}/>
				<Content>
					<Table settings={this.state.pages}/>
				</Content>
			</>
	}
}

export default PageList;
