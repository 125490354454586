import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'

class ReportEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to report list', path: '/reports'},
            imgPath: 'storage/report-picture/',
            fields: [
                {
                    label: 'Informer information',
                    fields: {
                        authoritiesName: {
                            label: 'Authorities name',
                            type: 'text'
                        },
                        officerName: {
                            label: 'Name of the handling officer',
                            type: 'text'
                        },
                        phone: {
                            label: 'Mobile number of authorities',
                            type: 'text'
                        },
                        city: {
                            label: 'City',
                            type: 'text'
                        },
                        district: {
                            label: 'District',
                            type: 'text'
                        },
                    }
                },
                {
                    label: 'Nature of report',
                    fields: {
                        natureOfAuthentication: {
                            label: 'Nature of the authentication',
                            type: 'select',
                            options: [
                                {
                                    key: 'complaint', label: 'Complaint'
                                },
                                {
                                    key: 'thief', label: 'Thief'
                                },
                                {
                                    key: 'market-sweep', label: 'Market sweep'
                                },
                                {
                                    key: 'investigation', label: 'Investigation'
                                }
                            ]
                        },
                        brandInvolved: {
                            label: 'Brand involved',
                            type: 'text'
                        },
                        quantity: {
                            label: 'Quantity of the seizure',
                            type: 'number'
                        },
                        deadline: {
                            label: 'Deadline for the authentication',
                            type: 'date'
                        },
                    }
                },
                {
                    label: 'Report comments',
                    fields: {
                        authorityComment: {
                            label: 'Authority comments',
                            type: 'textarea',
                            disabled: true
                        },
                        stuComment: {
                            label: 'STU comments',
                            type: 'textarea'
                        },
                    }
                },
                {
                    label: 'Pictures',
                    fields: {
                        pictures: {
                            type: 'image'
                        },
                    }
                }
            ],
            report: null
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        API.getReport(this.props.match.params.reportId).then(report => this.setState({loading: false, report: report}))
    }

    saveEntity(fields) {
        this.setState({loading: true})
        let deadline = new Date(fields.deadline)
        deadline = deadline.setMinutes(deadline.getMinutes() + deadline.getTimezoneOffset())
        fields.deadline = deadline
        API.update('reports', this.props.match.params.reportId, fields).then(() => {
            toast.success('Report has been updated')
            this.props.history.push('/reports')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    deleteEntity = () => {
        var r = window.confirm("Are you sure you want to delete this report?");
        if (r === false) return

        this.setState({loading: true})
        API.deleteReport(this.props.match.params.reportId).then(() => {
            toast.success('Report was successfully deleted')
            this.props.history.push('/reports')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Edit report'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <Form fields={this.state.fields}
                          imgPath={this.state.imgPath}
                          entity={this.state.report}
                          cancel={() => this.props.history.push('/reports')}
                          save={this.saveEntity}
                          deleteEntity={this.deleteEntity}/>
                </Content>
            </>
    }
}

export default ReportEdit
