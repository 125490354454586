import React from 'react'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import PrivateRoute from '../../layout/privateRoute'
import Loader from '../../layout/loader.jsx'
import GeneralInfo from './generalInfo/form.jsx'
import TrainingMaterials from './trainingMaterials/form.jsx'

const routes = [
    {
        exact: true,
        path: '/brands/:brandId/products/new/generalInfo',
        component: GeneralInfo
    },
    {
        exact: true,
        path: '/brands/:brandId/products/new/trainingMaterials',
        component: TrainingMaterials
    }
]

class ProductNew extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Brand', path: `/brands/${props.match.params.brandId}/products`},
            product: null,
            tabs: {
                links: [
                    {
                        title: 'General information',
                        path: `/brands/${props.match.params.brandId}/products/new/generalInfo`
                    },
                    {
                        title: 'Training materials',
                        path: `/brands/${props.match.params.brandId}/products/new/trainingMaterials`
                    }
                ]
            }
        }
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            loading: false
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        return <>
            <Head title='Add product' breadcrumb={this.state.breadcrumb} tabs={this.state.tabs}/>
            <Content>
                {
                    routes.map(props => (
                        <PrivateRoute {...props} />
                    ))
                }
            </Content>
        </>
    }
}

export default ProductNew