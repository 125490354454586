import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import {debounce} from 'lodash'
import {toast} from "react-toastify"

// All names related to company are "brand" in DB
class UserList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {
                name: '',
                role: ''
            },
            users: {
                columns: [
                    {
                        label: 'User',
                        key: 'name'
                    },
                    {
                        label: 'Brand',
                        key: 'companyName'
                    },
                    {
                        label: 'Role',
                        key: 'role',
                        view: function(field) {
                            if (field === 'admin')
                                return 'Admin'
                            else if (field === 'authority')
                                return 'Authority'
                            else
                                return 'Brand'
                        }
                    },
                    {
                        label: 'Status',
                        key: 'status',
                        view: function(field) {
                            if (field === 'validated')
                                return 'Validated'
                            else
                                return 'Waiting for validation'
                        }
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/users/' + id + '/edit')
                        }
                    },
                    {
                        action: 'delete',
                        onClick: (id, key) => {
                            var r = window.confirm("Are you sure you want to delete this user?");
                            if (r === false) return

                            this.setState({loading: true})
                            API.deleteUser(id).then(() => {
                                toast.success('User was successfully deleted')
                                this.loadEntities()
                            }).catch(() => {
                                toast.error('An unexpected error has occured, please try again')
                                this.setState({loading: false})
                            })
                        }
                    }
                ],
                entities: [],
                filters: [
                    {
                        label: 'User name',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.name)
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        name: value ? '/' + value + '/i' : ''
                                    }
                                }, () => {
                                    this.loadEntities()
                                })
                        }, 500)
                    },
                    {
                        label: 'Role',
                        type: 'select',
                        value: null,
                        entities: [
                            {
                                key: '', name: 'All'
                            },
                            {
                                key: 'admin', name: 'Admin'
                            },
                            {
                                key: 'authority', name: 'Authority'
                            },
                            {
                                key: 'brand', name: 'Brand'
                            }
                        ],
                        onChange: value => {
                            if (value.key !== this.state.filters.role) {
                                this.setState({
                                    filters: {...this.state.filters, role: value.key ? value.key : ''},
                                    users: {
                                        ...this.state.users,
                                        filters: [this.state.users.filters[0], {
                                            ...this.state.users.filters[1],
                                            value: value.key
                                        }]
                                    }
                                }, () => {
                                    this.loadEntities()
                                })
                            }

                        }
                    }],
                actions: [{
                    classList: 'blue add',
                    label: 'New user',
                    onClick: () => this.props.history.push('/users/new')
                }],
                selectedItems: [],
                multiSelect: false,
                // onRowClick: (entity) => {
                //     this.props.history.push('/users/' + entity.id + '/edit')
                // }
            }
        }
    }

    componentDidMount() {
        API.getUsersDetails(this.state.filters).then(entities => {
            entities.forEach(entity => {
                if (entity.company) {
                    if (entity.company.name.en) {
                        entity.companyName = entity.company.name.en
                    } else {
                        entity.companyName = entity.company.name.zh
                    }
                }

            })
            this.setState({
                ...this.state,
                loading: false,
                users: {...this.state.users, entities: entities}
            })
        })
    }

    loadEntities() {
        API.getUsersDetails(this.state.filters).then(entities => {
            entities.forEach(entity => {
                if (entity.company) {
                    if (entity.company.name.en) {
                        entity.companyName = entity.company.name.en
                    } else {
                        entity.companyName = entity.company.name.zh
                    }
                }

            })
            this.setState({
                ...this.state,
                loading: false,
                users: {...this.state.users, entities: entities}
            })
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Users'}/>
                <Content>
                    <Table settings={this.state.users}/>
                </Content>
            </>
    }
}

export default UserList