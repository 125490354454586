import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'
import HomepageForm from './homepage/form.jsx'
import AboutusForm from './aboutus/form.jsx'
import CounterfeitForm from './counterfeit/form.jsx'

class PageEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Pages', path: '/pages'},
            fields: {
                label: {label: 'Page name', type: 'text', required: true},
                id: {label: 'Page ID', type: 'text', required: true},
            },
            page: null
        }
    }

    componentDidMount() {
        API.getPage(this.props.match.params.pageId).then(page => this.setState({loading: false, page: page}))
    }

    saveEntity = updatedEntity => {
        this.setState({loading: true})

        updatedEntity = updatedEntity.entity

        if (updatedEntity.type === 'aboutus') {
            updatedEntity.aboutUs?.forEach(content => {
                content.images?.forEach(image => {
                    let uri = image.uri.replace('storage/page-image/', '')
                    image.uri = uri
                    return image
                })
            })
        }

        API.update('pages', this.props.match.params.pageId, updatedEntity).then(() => {
            toast.success('Page has been updated')
            this.props.history.push('/pages')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    render() {
        const type = this.state.page?.type
        const titleText = (type === 'homepage' && 'Homepage') || (type === 'aboutus' && 'About us') || (type === 'counterfeit' && 'Counterfeit indicators')

        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={titleText}/>
                <Content>
                    {
                        type === 'homepage' &&
                        <HomepageForm
                            fields={this.state.fields}
                            entity={this.state.page}
                            cancel={() => this.props.history.push('/pages')}
                            save={this.saveEntity}
                        />
                    }
                    {
                        type === 'aboutus' &&
                        <AboutusForm
                            fields={this.state.fields}
                            entity={this.state.page}
                            cancel={() => this.props.history.push('/pages')}
                            save={this.saveEntity}
                        />
                    }
                    {
                        type === 'counterfeit' &&
                        <CounterfeitForm
                            fields={this.state.fields}
                            entity={this.state.page}
                            cancel={() => this.props.history.push('/pages')}
                            save={this.saveEntity}
                        />
                    }
                </Content>
            </>
    }
}

export default PageEdit