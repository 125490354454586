import React, {Component} from 'react'
import {EditorState, convertToRaw, ContentState} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

class EditorConvertToHTML extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editorState: EditorState.createEmpty(),
            editorContent: ''
        }
    }


    componentDidMount() {
        // const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>'
        const html = this.props.text
        if (html && html !== null && html !== undefined) {
            const contentBlock = htmlToDraft(html)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            const editorState = EditorState.createWithContent(contentState)

            this.setState({
                editorState: editorState
            })
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    onContentStateChange = (contentState) => {
        this.setState({
            contentState: contentState.blocks[0].text
        })
        const contentInHtml = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        this.props.currentContent(contentInHtml)
    }

    getFileBase64 = (file, callback) => {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        // Since FileReader is asynchronous,
        // we need to pass data back.
        reader.onload = () => callback(reader.result)
        // TODO: catch an error
        reader.onerror = error => {
        }
    }

    uploadCallback = file => new Promise(
        (resolve, reject) => this.getFileBase64(
            file,
            data => resolve({data: {link: data}})
        )
    )

    render() {
        const {editorState} = this.state
        return (
            <div>
                <Editor
                    editorState={editorState}
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor-container"
                    onEditorStateChange={this.onEditorStateChange}
                    onContentStateChange={this.onContentStateChange}
                    toolbar={{
                        image: {
                            uploadCallback: this.uploadCallback.bind(this),
                            previewImage: true,
                            alt: {
                                present: true,
                                mandatory: false
                            },
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            defaultSize: {
                                height: 'auto',
                                width: '250',
                            },
                        },
                    }}
                />
            </div>
        )
    }
}

export default EditorConvertToHTML