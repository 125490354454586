import React from 'react'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import PrivateRoute from '../../layout/privateRoute'
import GeneralInfo from './generalInfo/form.jsx'
import TrainingMaterials from './trainingMaterials/form.jsx'

const routes = [
    {
        exact: true,
        path: '/brands/:brandId/products/:productId/edit/generalInfo',
        component: GeneralInfo
    },
    {
        exact: true,
        path: '/brands/:brandId/products/:productId/edit/trainingMaterials',
        component: TrainingMaterials
    }
]

class ProductEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumb: {title: 'Back to Brand', path: `/brands/${props.match.params.brandId}/products`},
            product: null,
            tabs: {
                links: [
                    {
                        title: 'General information',
                        path: `/brands/${props.match.params.brandId}/products/${props.match.params.productId}/edit/generalInfo`
                    },
                    {
                        title: 'Training materials',
                        path: `/brands/${props.match.params.brandId}/products/${props.match.params.productId}/edit/trainingMaterials`
                    }
                ]
            }
        }
    }

    componentDidMount() {
    }

    render() {
        return <>
            <Head title='Edit product' breadcrumb={this.state.breadcrumb} tabs={this.state.tabs}/>
            <Content>
                {
                    routes.map(props => (
                        <PrivateRoute {...props} />
                    ))
                }
            </Content>
        </>
    }
}

export default ProductEdit