import React from 'react'
import FileHandler from '../../layout/file-handler/fileHandler.jsx'
import Loader from '../../layout/loader.jsx'
import EditorConvertToHTML from "../../layout/editor.jsx"

class AboutusForm extends React.Component {
    constructor(props) {
        super(props)
        const entity = props.entity

        this.state = {
            entity
        }
    }

    createCorrectUrl = (arrayOfImages) => {
        let urlPrefix = 'storage/page-image/'

        const newArray = arrayOfImages?.map(image => {
            let urlEnd = image.uri
            let url = urlPrefix + urlEnd

            image.uri = url
            return image
        })

        return newArray
    }

    componentDidMount() {
        const entity = this.props.entity
        var images = []

        for (const i in entity.aboutUs) {
            images[i] = []
        }

        Object.keys(entity.aboutUs).forEach(index => {
            images[index] = this.createCorrectUrl(entity.aboutUs[index]?.images)
        })

        this.setState({
            entity,
            temporaryImages: {
                images: images
            }
        })
    }

    showImage = (file, fileObj, type, index) => {
        var images = []
        images = this.state.temporaryImages.images

        var entity = this.state.entity
        entity.aboutUs[index].images.push(fileObj)

        // Create a correct url for the new file
        var tempFileArray = [fileObj]
        tempFileArray = this.createCorrectUrl(tempFileArray)
        images[index].push(tempFileArray[0])

        this.setState(previousState => ({
            entity,
            temporaryImages: {
                images
            }
        }))
    }

    removeImage = (index, subindex, type) => {
        const temporaryImages = {...this.state.temporaryImages}
        const entity = {...this.state.entity}

        temporaryImages[type][index].splice(subindex, 1)
        entity.aboutUs[index][type].splice(subindex, 1)

        const images = temporaryImages.images

        this.setState(previousState => ({
            entity,
            temporaryImages: {
                images
            }
        }))
    }

    updateField(e, key) {
        this.setState({
            entity: {
                ...this.state.entity,
                [key]: e.target.value
            }
        })
    }

    // For complicated structure
    handleChange = (e, type, key, index = 0, editor = false) => {
        let newValue = e
        let {entity} = this.state

        if (editor) {
            entity.aboutUs[index][type][key] = newValue
        } else {
            entity.aboutUs[index][key] = newValue
        }

        this.setState(previousState => ({
            ...previousState,
            entity
        }))
    }

    render() {
        const {entity} = this.state

        if (this.state.temporaryImages === undefined)
            return <Loader/>
        else
            return <>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.props.save({...this.state})
                }} className="custom-form">
                    <div className="actions clearfix">
                        <div className="buttons clearfix">
                            <button className="grey" onClick={(e) => {
                                e.preventDefault();
                                this.props.cancel()
                            }}>Cancel
                            </button>
                            <button className="blue">Save page</button>
                        </div>
                    </div>

                    <div className="block above">
                        <div className="fields container clearfix">
                            <h3>Page information</h3>
                            <div className="field" key="pageName">
                                <label>Page name</label>
                                <input type="text"
                                       onChange={(e) => this.updateField(e, 'pageName')}
                                       value={entity?.pageName || ""}/>
                            </div>
                            <div className="field" key="pageId">
                                <label>Page ID</label>
                                <input type="text"
                                       onChange={(e) => this.updateField(e, 'pageId')}
                                       value={entity?.pageId || ""}/>
                            </div>
                        </div>

                        {
                            Object.keys(entity.aboutUs).map(index => {
                                return (
                                    <div className="fields container clearfix">
                                        <h3>Section {parseInt(index) + 1}</h3>
                                        <div className="file-handler-container">
                                            {this.state.temporaryImages?.images && Object.keys(this.state.temporaryImages?.images[index])?.map((subindex) => (
                                                <div className="field" key={"images"[index][subindex]}>
                                                    <FileHandler
                                                        image={this.state.temporaryImages?.images[index][subindex].uri}
                                                        name={this.state.temporaryImages?.images[index][subindex].name}
                                                        removeImage={(type) => this.removeImage(index, subindex, type)}
                                                        type={'images'}
                                                        label={'Image'}
                                                        fileType={'images'}
                                                        path={'page-image'}
                                                    />
                                                </div>
                                            ))}

                                            <div className="field" key={"images"}>
                                                <FileHandler
                                                    showImage={this.showImage}
                                                    type={'images'}
                                                    label={'Image'}
                                                    fileType={'images'}
                                                    path={'page-image'}
                                                    index={index}
                                                    dropzone
                                                />
                                            </div>
                                        </div>
                                        <div className="field" key="title">
                                            <label>Title</label>
                                            <input type="text"
                                                   onChange={(e) => this.handleChange(e.target.value, '', 'title', index, false)}
                                                   value={entity?.aboutUs[index].title || ""}/>
                                        </div>
                                        <div className="field" key="subtitle">
                                            <label>Subtitle</label>
                                            <input type="text"
                                                   onChange={(e) => this.handleChange(e.target.value, '', 'subtitle', index, false)}
                                                   value={entity?.aboutUs[index].subtitle || ""}/>
                                        </div>

                                        <div className="long-field" key="descriptionEn">
                                            <label>English Description text</label>
                                            <EditorConvertToHTML
                                                text={entity?.aboutUs[index].description.en}
                                                currentContent={(newText) => this.handleChange(newText, 'description', 'en', index, true)}
                                            />
                                        </div>

                                        <div className="long-field" key="descriptionZh">
                                            <label>Chinese Description text</label>
                                            <EditorConvertToHTML
                                                text={entity?.aboutUs[index].description.zh}
                                                currentContent={(newText) => this.handleChange(newText, 'description', 'zh', index, true)}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </form>
            </>
    }
}

export default AboutusForm