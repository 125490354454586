import React from 'react'
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import Table from '../../layout/table.jsx'
import moment from 'moment'
import {toast} from 'react-toastify'

class ProductList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Brand list', path: '/brands'},
            filters: {
                brand: this.props.match.params.brandId
            },
            sort: ['updatedAt', 'DESC'],
            products: {
                columns: [
                    {
                        label: 'Product name in English',
                        key: 'nameEn',
                        canSort: true,
                        onSortClick: column => {
                            if (this.state.sort === []) {
                                this.setState({
                                    sort: ['name.en', 'DESC']
                                })
                            } else if (this.state.sort[0] !== 'name.en') {
                                this.setState({
                                    sort: ['name.en', 'ASC']
                                })
                            } else if (this.state.sort[0] === 'name.en' && this.state.sort[1] === 'ASC') {
                                this.setState({
                                    sort: ['name.en', 'DESC']
                                })
                            } else if (this.state.sort[0] === 'name.en' && this.state.sort[1] === 'DESC') {
                                this.setState({
                                    sort: ['name.en', 'ASC']
                                })
                            }

                            this.loadEntities()
                        },
                    },
                    {
                        label: 'Product name in Chinese',
                        key: 'nameZh',
                        canSort: true,
                        onSortClick: column => {
                            if (this.state.sort === []) {
                                this.setState({
                                    sort: ['name.zh', 'DESC']
                                })
                            } else if (this.state.sort[0] !== 'name.zh') {
                                this.setState({
                                    sort: ['name.zh', 'ASC']
                                })
                            } else if (this.state.sort[0] === 'name.zh' && this.state.sort[1] === 'ASC') {
                                this.setState({
                                    sort: ['name.zh', 'DESC']
                                })
                            } else if (this.state.sort[0] === 'name.zh' && this.state.sort[1] === 'DESC') {
                                this.setState({
                                    sort: ['name.zh', 'ASC']
                                })
                            }

                            this.loadEntities()
                        },
                    },
                    {
                        label: 'Updated',
                        key: 'updatedAt',
                        canSort: true,
                        onSortClick: column => {
                            if (this.state.sort === []) {
                                this.setState({
                                    sort: ['updatedAt', 'DESC']
                                })
                            } else if (this.state.sort[0] !== 'updatedAt') {
                                this.setState({
                                    sort: ['updatedAt', 'ASC']
                                })
                            } else if (this.state.sort[0] === 'updatedAt' && this.state.sort[1] === 'ASC') {
                                this.setState({
                                    sort: ['updatedAt', 'DESC']
                                })
                            } else if (this.state.sort[0] === 'updatedAt' && this.state.sort[1] === 'DESC') {
                                this.setState({
                                    sort: ['updatedAt', 'ASC']
                                })
                            }

                            this.loadEntities()
                        },
                        view: function (field) {
                            return moment(field).format('YYYY-MM-DD')
                        }
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push(`/brands/${this.props.match.params.brandId}/products/${id}/edit/generalInfo`)
                        }
                    },
                    {
                        action: 'delete',
                        onClick: (id, key) => {
                            var r = window.confirm("Are you sure you want to delete this product?");
                            if (r === false) return

                            this.setState({loading: true})
                            API.deleteProduct(id).then(() => {
                                toast.success('Product was successfully deleted')
                                this.loadEntities()
                            }).catch(() => {
                                toast.error('An unexpected error has occured, please try again')
                                this.setState({loading: false})
                            })
                        }
                    }
                ],
                entities: [],
                filters: [],
                actions: [{
                    classList: 'blue add',
                    label: 'New product',
                    onClick: () => this.props.history.push(`/brands/${this.props.match.params.brandId}/products/new/generalInfo`)
                }],
                selectedItems: [],
                multiSelect: false,
                // onRowClick: (entity) => {
                //     this.props.history.push(`/brands/${this.props.match.params.brandId}/products/${entity.id}/edit/generalInfo`)
                // }
            },
            brandInfo: {}
        }
    }

    componentDidMount() {
        Promise.all([
            API.getBrand(this.props.match.params.brandId).then(entity => {
                if (entity.name.en) {
                    entity.brandName = entity.name.en
                } else {
                    entity.brandName = entity.name.zh
                }

                this.setState({
                    brandInfo: entity
                })
            }),

            API.getProductsDetails(this.state.filters, this.state.sort).then(entities => {
                entities.forEach(entity => {
                    if (entity.name) {
                        if (entity.name.en) {
                            entity.nameEn = entity.name.en
                        } else {
                            entity.nameEn = '-'
                        }

                        if (entity.name.zh) {
                            entity.nameZh = entity.name.zh
                        } else {
                            entity.nameZh = '-'
                        }
                    }
                })
                this.setState({
                    products: {...this.state.products, entities: entities}
                })
            })
        ]).then(() => {
            setTimeout(() => {
                this.setState({loading: false})
            }, 100)
        })
    }

    loadEntities() {
        API.getProductsDetails(this.state.filters, this.state.sort).then(entities => {
            entities.forEach(entity => {
                if (entity.name) {
                    if (entity.name.en) {
                        entity.nameEn = entity.name.en
                    } else {
                        entity.nameEn = '-'
                    }

                    if (entity.name.zh) {
                        entity.nameZh = entity.name.zh
                    } else {
                        entity.nameZh = '-'
                    }
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                products: {...this.state.products, entities: entities}
            })
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={this.state.brandInfo?.brandName} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <Table settings={this.state.products}/>
                </Content>
            </>
    }
}

export default ProductList
