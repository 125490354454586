import React from 'react'
import Logo from '../layout/logo'
import User from '../../services/user'
import DB from '../../services/db'
import Loader from '../layout/loader.jsx'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.login = this.login.bind(this)
        this.state = {
            name: '',
            password: '',
            loading: false
        }
    }

    // On component mount, add class on body
    componentDidMount() {
        document.body.classList.add('fullscreen')
    }

    // On component unmount, remove class on body
    componentWillUnmount() {
        document.body.classList.remove('fullscreen')
    }

    // User login
    login(event) {
        this.setState({loading: true})
        event.preventDefault();
        const {history} = this.props

        User.login({
            name: this.state.name,
            password: this.state.password
        }).then(() => {
            if (DB.get('user').role === 'admin') {
                this.setState({loading: false})
                history.push('/')
            } else {
                this.setState({error: true, loading: false})
            }
        }).catch((err) => {
            this.setState({error: true, loading: false})
        })

    }

    render() {
        return <div className="login">
            <div className="form-wrapper">
                <Logo/>
                <div className="block">
                    {
                        this.state.loading ?
                            <Loader/> : <>
                                <h1 style={{color: "#1A1B27", marginBottom: "55px"}}>Sign in</h1>
                                {this.state.error && <p>Name or password not recognized, please try again</p>}
                                <form onSubmit={this.login} className="fields">
                                    <div className="field" key="username">
                                        <label style={{marginBottom: "7px"}}>Username</label>
                                        <input type="name" required placeholder="Username" value={this.state.name}
                                               onChange={(e) => this.setState({name: e.target.value})}
                                               style={{width: "100%", height: "43px"}}
                                        />
                                    </div>
                                    <div className="field" key="password">
                                        <label style={{marginBottom: "7px"}}>Password</label>
                                        <input type="password" required placeholder="Password" value={this.state.password}
                                               onChange={(e) => this.setState({password: e.target.value})}
                                               style={{width: "100%", height: "43px"}}
                                        />
                                    </div>
                                    <button className="black"
                                            style={{width: "100%", height: "42px", borderRadius: "2px"}}>Sign in
                                    </button>
                                </form>
                            </>
                    }
                </div>
            </div>
        </div>
    }
}

export default Login
