import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import {debounce} from 'lodash'

class BrandList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {
                name: ''
            },
            brands: {
                columns: [
                    {
                        label: 'Brand name in English',
                        key: 'nameEn',
                    },
                    {
                        label: 'Brand name in Chinese',
                        key: 'nameZh',
                    },
                    {
                        label: 'Promoted',
                        key: 'promoted',
                        view: function (field) {
                            if (field) {
                                if (field === true)
                                    return 'Promoted'
                                else
                                    return '-'
                            } else
                                return '-'
                        }
                    },
                    {
                        content: 'See products',
                        action: 'view',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/brands/' + id + '/products')
                        }
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/brands/' + id + '/edit')
                        }
                    }
                ],
                entities: [],
                filters: [
                    {
                        label: 'Brand name',
                        onChange: debounce(value => {
                            if (value !== this.state.filters.name)
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        name: value ? value : ''
                                    }
                                }, () => {
                                    this.loadEntities()
                                })
                        }, 500)
                    }
                ],
                actions: [{
                    classList: 'blue add',
                    label: 'New brand',
                    onClick: () => this.props.history.push('/brands/new')
                }],
                selectedItems: [],
                multiSelect: false,
                // onRowClick: (entity) => {
                //     this.props.history.push('/brands/' + entity.id + '/products')
                // }
            }
        }
    }

    componentDidMount() {
        API.getBrandsDetails().then(entities => {
            entities.forEach(entity => {
                if (entity.name) {
                    if (entity.name.en) {
                        entity.nameEn = entity.name.en
                    } else {
                        entity.nameEn = '-'
                    }

                    if (entity.name.zh) {
                        entity.nameZh = entity.name.zh
                    } else {
                        entity.nameZh = '-'
                    }
                }
            })
            this.setState({
                ...this.state,
                loading: false,
                brands: {...this.state.brands, entities: entities}
            })
        })
    }

    loadEntities() {
        API.getBrandsDetails().then(entities => {
            entities.forEach(entity => {
                if (entity.name) {
                    if (entity.name.en) {
                        entity.nameEn = entity.name.en
                    } else {
                        entity.nameEn = '-'
                    }

                    if (entity.name.zh) {
                        entity.nameZh = entity.name.zh
                    } else {
                        entity.nameZh = '-'
                    }
                }
            })

            let result = []
            entities.forEach(brand => {
                if (brand.nameEn.toLowerCase().indexOf(this.state.filters.name.toLowerCase()) !== -1 || brand.nameZh.indexOf(this.state.filters.name.toLowerCase()) !== -1) {
                    result.push(brand)
                }
            })

            this.setState({
                ...this.state,
                loading: false,
                brands: {...this.state.brands, entities: result}
            })
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Brands'}/>
                <Content>
                    <Table settings={this.state.brands}/>
                </Content>
            </>
    }
}

export default BrandList
