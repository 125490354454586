import React, {useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import API from '../../../services/api.jsx'

function Dropzone(props) {
    const [files, setFiles] = useState([])
    const [acceptableType, setAcceptableType] = useState('')
    const fileTypeProps = props.fileType
    const path = props.path

    const {getRootProps, getInputProps} = useDropzone({
        accept: acceptableType,
        onDrop: acceptedFiles => {
            if (fileTypeProps === 'images' || fileTypeProps === 'image') {
                for (const i in acceptedFiles) {
                    API.postImage(path, acceptedFiles[i]).then(acceptedFileTemp => {
                        props.showImage(acceptedFiles[i], acceptedFileTemp, 'images')
                    })
                }
            } else {
                // props.showFile(acceptedFiles[0])
            }
        }
    })

    useEffect(() => {
        if (fileTypeProps === 'images' || fileTypeProps === 'image') {
            setAcceptableType('image/*')
        } else {
            setAcceptableType('application/pdf')
        }
    }, [fileTypeProps])


    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        if (files.length) {
            files.forEach(file => {
                URL.revokeObjectURL(file.preview)
            })
        }
    }, [files])

    return (
        <>
            {props.label !== '' && <label>{props.label}</label>}
            <div key={props.key}{...getRootProps({className: 'dropzone image-wrap'})}>
                <input {...getInputProps()} disabled={props.disabled}/>
                <div className="image-icon-wrap icon-downloading">
                    <p>Drag and drop a new file</p>
                </div>
            </div>
        </>
    )
}

export default Dropzone