import React from 'react';
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'

class CategoryList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            filters: {},
            categories: {
                columns: [
                    {
                        label: 'Category name in English',
                        key: 'nameEn'
                    },
                    {
                        label: 'Category name in Chinese',
                        key: 'nameZh'
                    },
                    {
                        content: 'Edit',
                        action: 'edit',
                        className: 'edit-link',
                        onClick: (id, key) => {
                            this.props.history.push('/categories/' + id + '/edit')
                        }
                    }],
                entities: [],
                filters: [],
                actions: [{
                    classList: 'blue add',
                    label: 'New category',
                    onClick: () => this.props.history.push('/categories/new')
                }],
                selectedItems: [],
                multiSelect: false,
                // onRowClick: (entity) => {
                //     this.props.history.push('/categories/' + entity.id + '/edit')
                // }
            }
        }
    }

    componentDidMount() {
        API.getCategories(this.state.filters).then(entities => {
            var categoryList = []
            entities.forEach(entity => {
                let categoryName = {
                    id: entity.id,
                    nameEn: entity.name.en,
                    nameZh: entity.name.zh
                }
                categoryList.push(categoryName)
            })
            this.setState({
                ...this.state,
                loading: false,
                categories: {...this.state.categories, entities: categoryList}
            })
        })
    }

    loadEntities() {
        API.getCategories(this.state.filters).then(entities => this.setState({
            ...this.state,
            loading: false,
            categories: {...this.state.categories, entities: entities}
        }))
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Categories'}/>
                <Content>
                    <Table settings={this.state.categories}/>
                </Content>
            </>
    }
}

export default CategoryList
