import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'

// All names related to company are "brand" in DB
class UserNew extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to users', path: '/users'},
            roleOptions: [
                {
                    key: 'admin', label: 'Admin'
                },
                {
                    key: 'authority', label: 'Authority'
                },
                {
                    key: 'brand', label: 'Brand'
                }
            ],
            authorityTypeOptions: [
                {
                    key: 'gacc', label: 'GACC'
                },
                {
                    key: 'customs', label: 'Customs'
                },
                {
                    key: 'psb', label: 'PSB'
                },
                {
                    key: 'samr', label: 'SAMR'
                },
                {
                    key: 'msa', label: 'MSA'
                },
                {
                    key: 'others', label: 'Others'
                }
            ],
            companyOptions: [],
            user: {
                name: null,
                role: null,
                email: null,
                phone: null,
                company: null,
                companyName: null,
                password: null,
            },
            newBrandId: null
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        Promise.all([
            API.getBrandsDetails().then(brands => {
                var options = []
                options.push({id: '', label: ''})
                brands.forEach(brand => {
                    let option = {}
                    if (brand.name.en) {
                        option.key = brand.id
                        option.label = brand.name.en
                    } else {
                        option.key = brand.id
                        option.label = brand.name.zh
                    }
                    options.push(option)
                })
                options.push({id: '', label: 'Others'})
                this.setState({
                    companyOptions: options
                })
            })
        ]).then(() => {
            setTimeout(() => {
                this.setState({loading: false})
            }, 100)
        })
    }

    updateField(e, key) {
        this.setState({
            user: {
                ...this.state.user,
                [key]: e.target.value
            }
        })
    }

    saveEntity(fields) {
        this.setState({loading: true})

        if (!this.state.user.name || !this.state.user.email) {
            toast.error('Username and Email can not be empty')
            this.setState({loading: false})
        } else {
            if (this.state.user.companyName) {
                if (this.state.newBrandId === null) {
                    const newBrand = {
                        name: {
                            en: this.state.user.companyName,
                            zh: ''
                        },
                        agentType: 'other-agent'
                    }

                    // If user choose 'Others' in select field, it will create a new company
                    API.create('brands', newBrand).then((result) => {
                        const updateBrandId = {
                            company: result.id
                        }

                        fields.company = result.id

                        this.setState({newBrandId: result.id})

                        API.update('brands', result.id, updateBrandId).then(() => {
                            API.create('users', fields).then((user) => {
                                API.sendEmailToAdmin(user.id).then(() => {
                                    toast.success('User has been created')
                                    this.props.history.push('/users')
                                }).catch(() => {
                                    // toast.error('An unexpected error has occured, please try again')
                                    // this.setState({loading: false})
                                })
                                // toast.success('User has been created')
                                // this.props.history.push('/users')
                            }).catch(() => {
                                // toast.error('An unexpected error has occured, please try again')
                                toast.error('Email or username has been used')
                                this.setState({loading: false})
                            })
                        }).catch(() => {
                            toast.error('An unexpected error has occured, please try again')
                            this.setState({loading: false})
                        })
                    }).catch(() => {
                        toast.error('An unexpected error has occured, please try again')
                        this.setState({loading: false})
                    })
                } else {
                    fields.company = this.state.newBrandId

                    API.create('users', fields).then((user) => {
                        API.sendEmailToAdmin(user.id).then(() => {
                            toast.success('User has been created')
                            this.props.history.push('/users')
                        }).catch(() => {
                            // toast.error('An unexpected error has occured, please try again')
                            // this.setState({loading: false})
                        })
                        // toast.success('User has been created')
                        // this.props.history.push('/users')
                    }).catch(() => {
                        // toast.error('An unexpected error has occured, please try again')
                        toast.error('Email or username has been used')
                        this.setState({loading: false})
                    })
                }
            } else {
                API.create('users', fields).then(() => {
                    toast.success('User has been created')
                    this.props.history.push('/users')
                }).catch(() => {
                    // toast.error('An unexpected error has occured, please try again')
                    toast.error('Email or username has been used')
                    this.setState({loading: false})
                })
            }
        }
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Add user'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <form onSubmit={(e) => this.onSubmit(e, () => this.saveEntity(this.state.user))}>
                        <div className="actions clearfix">
                            <div className="buttons clearfix">
                                <button className="grey"
                                        onClick={(e) => this.onCancel(e, () => this.props.history.push('/users'))}>Cancel
                                </button>
                                <button className="blue">Save</button>
                            </div>
                        </div>

                        <div className="block">
                            <div className="fields clearfix">
                                <div className="fields clearfix">
                                    <h3>Edit information</h3>
                                    <div className="field" key="name">
                                        <label>User name</label>
                                        <input type="text"
                                               onChange={(e) => this.updateField(e, 'name')}
                                               value={this.state.user.name}/>
                                    </div>
                                    <div className="field" key="role">
                                        <label>Role</label>
                                        <select
                                            onChange={(e) => this.updateField(e, 'role')}
                                            value={this.state.user.role}>
                                            {
                                                this.state.roleOptions.map(option =>
                                                    <option
                                                        value={option.key}>{option.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    {
                                        this.state.user.role === "authority" &&
                                        <div className="field" key="authorityType">
                                            <label>Authority type</label>
                                            <select
                                                onChange={(e) => this.updateField(e, 'authorityType')}
                                                value={this.state.user.authorityType}>
                                                {
                                                    this.state.authorityTypeOptions.map(option =>
                                                        <option
                                                            value={option.key}>{option.label}</option>)
                                                }
                                            </select>
                                        </div>
                                    }
                                    <div className="field" key="email">
                                        <label>Email</label>
                                        <input type="email"
                                               onChange={(e) => this.updateField(e, 'email')}
                                               value={this.state.user.email}/>
                                    </div>
                                    <div className="field" key="phone">
                                        <label>Phone number</label>
                                        <input type="text"
                                               onChange={(e) => this.updateField(e, 'phone')}
                                               value={this.state.user.phone}/>
                                    </div>
                                    <div className="field" key="company">
                                        <label>Brand</label>
                                        <select
                                            onChange={(e) => this.updateField(e, 'company')}
                                            value={this.state.user.company}>
                                            {
                                                this.state.companyOptions.map(option =>
                                                    <option
                                                        value={option.key}>{option.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    {
                                        this.state.user.company === "Others" &&
                                        <div className="field" key="companyName">
                                            <label>Brand name</label>
                                            <input type="text"
                                                   onChange={(e) => this.updateField(e, 'companyName')}
                                                   value={this.state.user.companyName}/>
                                        </div>
                                    }
                                    <div className="field" key="password">
                                        <label>Password</label>
                                        <input type="password"
                                               onChange={(e) => this.updateField(e, 'password')}
                                               value={this.state.user.password}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Content>
            </>
    }
}

export default UserNew
