import React from 'react'
import FileHandler from "../../../layout/file-handler/fileHandler"
import {toast} from "react-toastify"
import API from "../../../../services/api"
import Loader from "../../../layout/loader"

class GeneralInfo extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Brand', path: `/brands/${props.match.params.brandId}/products`},
            categoryOptions: [],
            temporaryImages: {
                images: []
            },
            product: {
                name: {
                    en: null,
                    zh: null
                },
                category: null,
                images: [],
                description: {
                    en: null,
                    zh: null
                },
                brand: props.match.params.brandId,
                trainingMaterials: []
            }
        }

        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        if (this.props.match.params.productId) {
            Promise.all([
                API.getProduct(this.props.match.params.productId).then(product => {
                    var images = this.createCorrectUrl(product.images)

                    this.setState({
                        temporaryImages: {
                            images: images
                        },
                        product: product
                    })
                }),
                API.getCategories().then(categories => {
                    var options = []
                    options.push({key: '', label: '---Choose---'})
                    categories.forEach(category => {
                        let option = {}
                        if (category.name.en) {
                            option.key = category.id
                            option.label = category.name.en
                        } else {
                            option.key = category.id
                            option.label = category.name.zh
                        }
                        options.push(option)
                    })

                    if (!this.state.product.category) {
                        this.setState({
                            product: {
                                ...this.state.product,
                                category: options[0].key
                            }
                        })
                    }

                    this.setState({
                        categoryOptions: options
                    })
                })
            ]).then(() => {
                setTimeout(() => {
                    this.setState({loading: false})
                }, 100)
            })
        } else {
            API.getCategories().then(categories => {
                var options = []
                options.push({key: '', label: '---Choose---'})
                categories.forEach(category => {
                    let option = {}
                    if (category.name.en) {
                        option.key = category.id
                        option.label = category.name.en
                    } else {
                        option.key = category.id
                        option.label = category.name.zh
                    }
                    options.push(option)
                })
                this.setState({
                    loading: false,
                    categoryOptions: options,
                    product: {
                        ...this.state.product,
                        category: options[0].key
                    }
                })
            })
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    updateField(e, key) {
        this.setState({
            product: {
                ...this.state.product,
                [key]: e.target.value
            }
        })
    }

    saveEntity(fields) {
        this.setState({loading: true})

        if (!this.state.product.name.en && !this.state.product.name.zh) {
            toast.error('Product name can not be empty')
            this.setState({loading: false})
            return
        } else if (this.state.product.category === '') {
            toast.error('Category can not be empty')
            this.setState({loading: false})
            return
        } else {
            if (this.state.product.images) {
                this.state.product.images.map(image => {
                    let uri = image.uri.replace('storage/product-image/', '')
                    image.uri = uri
                    return image
                })
            }

            const newProduct = this.state.product

            if (this.props.match.params.productId) {
                API.update('products', this.props.match.params.productId, newProduct).then((result) => {
                    toast.success('Product has been updated')
                    this.props.history.push(`/brands/${this.props.match.params.brandId}/products`)
                }).catch(() => {
                    toast.error('An unexpected error has occured, please try again')
                    this.setState({loading: false})
                })
            } else {
                API.create('products', newProduct).then((result) => {
                    toast.success('Product has been created')
                    this.props.history.push(`/brands/${this.props.match.params.brandId}/products`)
                }).catch(() => {
                    toast.error('An unexpected error has occured, please try again')
                    this.setState({loading: false})
                })
            }
        }
    }

    deleteEntity = () => {
        var r = window.confirm("Are you sure you want to delete this product?");
        if (r === false) return

        this.setState({loading: true})
        API.deleteCategory(this.props.match.params.productId).then(() => {
            toast.success('Product was successfully deleted')
            this.props.history.push(`/brands/${this.props.match.params.brandId}/products`)
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    // For complicated structure
    handleChange = (e, type, key, index = 0, editor = false) => {
        let newValue = e
        let {product} = this.state

        product[type][key] = newValue

        this.setState(previousState => ({
            ...previousState,
            product
        }))
    }

    createCorrectUrl = (arrayOfImages) => {
        let urlPrefix = 'storage/product-image/'

        const newArray = arrayOfImages?.map(image => {
            let urlEnd = image.uri
            let url = urlPrefix + urlEnd

            image.uri = url
            return image
        })

        return newArray
    }

    showImage = (file, fileObj, type, index) => {
        var images = []
        images = this.state.temporaryImages.images

        var product = this.state.product
        product.images.push(fileObj)

        // Create a correct url for the new file
        var tempFileArray = [fileObj]
        tempFileArray = this.createCorrectUrl(tempFileArray)
        images.push(tempFileArray[0])

        this.setState(previousState => ({
            product,
            temporaryImages: {
                images
            }
        }))
    }

    removeImage = (index, type) => {
        const temporaryImages = {...this.state.temporaryImages}
        const product = {...this.state.product}

        temporaryImages[type].splice(index, 1)
        product[type].splice(index, 1)

        const images = temporaryImages.images

        this.setState(previousState => ({
            product,
            temporaryImages: {
                images
            }
        }))
    }

    onCancel(e, callback) {
        e.preventDefault()
        callback()
    }

    onSubmit(e, callback) {
        e.preventDefault()
        callback()
    }

    render() {
        const {product} = this.state

        if (this.state.loading)
            return <Loader/>
        return <>
            <form onSubmit={(e) => {
                this.onSubmit(e, () => this.saveEntity(this.state.product))
            }} className="custom-form">
                <div className="actions clearfix">
                    <div className="buttons clearfix">
                        <button className="grey"
                                onClick={(e) => this.onCancel(e, () => this.props.history.push(`/brands/${this.props.match.params.brandId}/products`))}>Cancel
                        </button>
                        <button className="blue">Save</button>
                    </div>
                </div>

                <div className="block above">
                    <div className="fields container clearfix">
                        <h3>Information</h3>
                        <div className="field" key="nameEn">
                            <label>Product name in English</label>
                            <input type="text"
                                   onChange={(e) => this.handleChange(e.target.value, 'name', 'en')}
                                   value={product?.name.en || ''}/>
                        </div>
                        <div className="field" key="nameZh">
                            <label>Product name in Chinese</label>
                            <input type="text"
                                   onChange={(e) => this.handleChange(e.target.value, 'name', 'zh')}
                                   value={product?.name.zh || ''}/>
                        </div>
                        <div className="field" key="category">
                            <label>Category</label>
                            <select
                                onChange={(e) => this.updateField(e, 'category')}
                                value={product?.category}>
                                {
                                    this.state.categoryOptions.map(option =>
                                        <option
                                            value={option.key}>{option.label}</option>)
                                }
                            </select>
                        </div>
                        <div className="field" key="retailPrice">
                            <label>Retail price</label>
                            <input type="number"
                                   onChange={(e) => this.updateField(e, 'retailPrice')}
                                   placeholder={'CNY'}
                                   value={product?.retailPrice}/>
                        </div>

                    </div>

                    <div className="fields container clearfix">
                        <h3>Image carousel</h3>
                        <div className="file-handler-container">
                            {this.state.temporaryImages?.images && Object.keys(this.state.temporaryImages?.images)?.map((index) => (
                                <div className="field" key={"images"[index]}>
                                    <FileHandler
                                        image={this.state.temporaryImages?.images[index].uri}
                                        name={this.state.temporaryImages?.images[index].name}
                                        removeImage={(type) => this.removeImage(index, type)}
                                        type={'images'}
                                        label={'Image'}
                                        fileType={'images'}
                                        path={'page-image'}
                                    />
                                </div>
                            ))}

                            <div className="field" key="images">
                                <FileHandler
                                    showImage={this.showImage}
                                    type={'images'}
                                    label={'Image'}
                                    fileType={'images'}
                                    path={'product-image'}
                                    dropzone
                                />
                            </div>
                        </div>
                    </div>

                    <div className="fields container clearfix">
                        <h3>Description</h3>
                        <div className="long-field" key="descriptionEn">
                            <label>Description text in English</label>

                            {/*<input type="textarea"*/}
                            {/*       onChange={(e) => this.handleChange(e.target.value, 'description', 'en')}*/}
                            {/*       value={product?.description?.en || ''}/>*/}
                            <textarea onChange={(e) => this.handleChange(e.target.value, 'description', 'en')}
                                      value={product?.description?.en || ''}/>
                        </div>
                        <div className="long-field" key="descriptionZh">
                            <label>Description text in Chinese</label>

                            {/*<input type="textarea"*/}
                            {/*       onChange={(e) => this.handleChange(e.target.value, 'description', 'zh')}*/}
                            {/*       value={product?.description?.zh || ''}/>*/}
                            <textarea onChange={(e) => this.handleChange(e.target.value, 'description', 'zh')}
                                      value={product?.description?.zh || ''}/>
                        </div>
                    </div>
                </div>

                {
                    this.props.match.params.productId &&
                    <div className="actions clearfix below-form">
                        <button onClick={() => this.deleteEntity()} className="red">Delete product</button>
                    </div>
                }
            </form>
        </>
    }
}

export default GeneralInfo