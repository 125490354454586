import React, {useState, useEffect} from 'react';

function SelectComponent({...props}) {
    let [className, setClassName] = useState('')
    let status = props.entity.status

    const onChange = e => {
        props.onChange(e, props.entity)
    }

    useEffect(() => {
        switch (status) {
            case 'ongoing':
                return setClassName('yellow')
            case 'closed':
                return setClassName('grey')
            default:
                return setClassName('yellow')
        }
    }, [status])

    return (
        <select className={'status ' + className} onChange={(e) => onChange(e)} value={status}>
            <option value="ongoing">Ongoing</option>
            <option value="closed">Closed</option>
        </select>
    )
}

export default SelectComponent;