import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from '../layout/form.jsx'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import {toast} from 'react-toastify'

class CategoryEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            breadcrumb: {title: 'Back to Categories', path: '/categories'},
            fields: [
                {
                    label: 'Informations',
                    fields: {
                        nameEn: {
                            label: 'Category name in English',
                            type: 'text'
                        },
                        nameZh: {
                            label: 'Category name in Chinese',
                            type: 'text'
                        },
                    }
                }
            ],
            category: null
        }
        this.saveEntity = this.saveEntity.bind(this)
    }

    componentDidMount() {
        API.getCategory(this.props.match.params.categoryId).then(category => {
            category.nameEn = category.name.en
            category.nameZh = category.name.zh
            this.setState({loading: false, category: category})
        })
    }

    saveEntity(fields) {
        this.setState({loading: true})

        fields.name.en = fields.nameEn
        fields.name.zh = fields.nameZh
        delete fields.nameEn
        delete fields.nameZh

        API.update('categories', this.props.match.params.categoryId, fields).then(() => {
            toast.success('Category has been updated')
            window.location.reload(true)
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    deleteEntity = () => {
        var r = window.confirm("Are you sure you want to delete this category?");
        if (r === false) return

        this.setState({loading: true})
        API.deleteCategory(this.props.match.params.categoryId).then(() => {
            toast.success('Category was successfully deleted')
            this.props.history.push('/categories')
        }).catch(() => {
            toast.error('An unexpected error has occured, please try again')
            this.setState({loading: false})
        })
    }

    render() {
        if (this.state.loading)
            return <Loader/>
        else
            return <>
                <Head title={'Edit Category'} breadcrumb={this.state.breadcrumb}/>
                <Content>
                    <Form fields={this.state.fields}
                          entity={this.state.category}
                          cancel={() => this.props.history.push('/categories')}
                          save={this.saveEntity}
                          deleteEntity={this.deleteEntity}/>
                </Content>
            </>
    }
}

export default CategoryEdit;
